var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tech-req__card"},[_c('div',{staticClass:"tech-req__card_container"},[_c('div',{staticClass:"tech-req__card_title"},[_vm._v(_vm._s(_vm.tech.m_material[_vm.language]))]),_c('Select',{staticClass:"tech-req__card_select_line",attrs:{"type":"search","query":_vm.findMaterials,"name":"material","defaultValue":_vm.defaultMaterial,"design":"contrast","title":'name'},on:{"change":function($event){return _vm.materialChange($event)}}}),_c('div',{staticClass:"tech-req__card_title"},[_vm._v(_vm._s(_vm.tech.m_gost[_vm.language]))]),(_vm.language === 'ru')?_c('Select',{staticClass:"tech-req__card_select_line",attrs:{"type":"select","name":"material_gost","defaultValue":"Не указан","design":"contrast","values":[
              {
                title: 'Не указан',
              },
            ],"title":'title'}}):_vm._e(),(_vm.language === 'en')?_c('Select',{staticClass:"tech-req__card_select_line",attrs:{"type":"select","name":"material_gost","defaultValue":"Not specified","design":"contrast","values":[
              {
                title: 'Not specified',
              },
            ],"title":'title'}}):_vm._e()],1)]),_c('div',{staticClass:"tech-req__card"},[_c('div',{staticClass:"tech-req__card_container"},[_c('div',{staticClass:"tech-req__card_title"},[_vm._v(_vm._s(_vm.tech.m_choice_blank[_vm.language]))]),_c('a-radio-group',{attrs:{"disabled":true},on:{"change":_vm.changeBlank},model:{value:(_vm.blankValue),callback:function ($$v) {_vm.blankValue=$$v},expression:"blankValue"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.tech.m_blank_exec[_vm.language])+" ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" "+_vm._s(_vm.tech.m_blank_cust[_vm.language])+" ")]),_c('a-radio',{attrs:{":value":3}},[_vm._v(" "+_vm._s(_vm.tech.m_blank_exec_and_cust[_vm.language])+" ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }