<template>
  <div>
    <div class="tech-req__card">
      <div class="tech-req__card_container">
        <div class="tech-req__card_title">{{ tech.m_material[language] }}</div>
        <Select type="search" :query="findMaterials"
                name="material"
                :defaultValue="defaultMaterial"
                design="contrast"
                :title="'name'"
                class="tech-req__card_select_line"
                @change="materialChange($event)" />
        <div class="tech-req__card_title">{{ tech.m_gost[language] }}</div>
        <Select type="select"
                v-if="language === 'ru'"
                name="material_gost"
                defaultValue="Не указан"
                design="contrast"
                :values="[
                {
                  title: 'Не указан',
                },
              ]"
                :title="'title'"
                class="tech-req__card_select_line" />
        <Select type="select"
                v-if="language === 'en'"
                name="material_gost"
                defaultValue="Not specified"
                design="contrast"
                :values="[
                {
                  title: 'Not specified',
                },
              ]"
                :title="'title'"
                class="tech-req__card_select_line" />
      </div>
    </div>
    <div class="tech-req__card">
      <div class="tech-req__card_container">
        <div class="tech-req__card_title">{{ tech.m_choice_blank[language] }}</div>
        <a-radio-group v-model="blankValue" @change="changeBlank" :disabled="true">
          <a-radio :value="1">
            {{ tech.m_blank_exec[language] }}
          </a-radio>
          <a-radio :value="2">
            {{ tech.m_blank_cust[language] }}
          </a-radio>
          <a-radio ::value="3">
            {{ tech.m_blank_exec_and_cust[language] }}
          </a-radio>
        </a-radio-group>
      </div>
    </div>
    <!--<div class="tech-req__card">
      <div class="tech-req__card_title">{{ tech.m_method_type[language] }}</div>
      <Select type="select"
              :values="this.$store.state.tech.methods"
              name="blank_type"
              design="contrast"
              :title="'name'"
              @change="setMethod($event)"
              class="tech-req__card_select_line" />
      <Select type="multiple"
              name="blank_subtype"
              design="contrast"
              v-if="showMethodType"
              :values="this.$store.getters['tech/getMethodTypes']"
              :title="'name'"
              class="tech-req__card_select_line" />
    </div>-->
  </div>
</template>

<script>
import tech from '@/assets/text/tech.json';
import { query } from '@/utilites/axios';

const Select = () => import('@/components/select/I5Select.vue');

export default {
  name: 'MaterialAndBlank',
  data() {
    return {
      tech,
      defaultMaterial: null,
      blankValue: 1,
      showMaterialGost: false,
      showMethodType: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    materialChange() {
      this.showMaterialGost = true;
    },
    changeBlank(e) {
      this.blankValue = e.target.value;
    },
    setMethod(e) {
      this.$store.commit('tech/setMethodId', e.id_method);
      this.showMethodType = true;
    },
    async getPartData() {
      await this.$store.dispatch('part/getDataForUploadedFile', {
        data: {
          hash: this.$route.query.h,
          time_stamp: this.$route.query.t,
          file_name: this.$route.query.n,
          lang: this.language,
        },
      }).then((item) => {
        if (item && item.part) {
          this.defaultMaterial = item.part.material_name;
        }
      });
    },
    findMaterials(val) {
      return new Promise((resolve) => {
        query('post',
          'getMaterialsAsArray', {
            find: val,
            lang: this.language,
          }, {},
          () => {}).then((items) => {
          resolve(items);
        });
      });
    },
  },
  components: {
    Select,
  },
  created() {
    this.$store.dispatch('tech/getBlanksByPart', {
      hash: this.$route.query.h,
      time_stamp: this.$route.query.t,
      file_name: this.$route.query.n,
      amount: 1,
    });
    this.getPartData();
    this.$store.watch((state) => state.index.language, () => {
      this.getPartData();
    });
  },
};
</script>
